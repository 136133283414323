<template>
  <side-form :triggerOpen="triggerOpen" :title="$t('shifts.details')">
    <template v-slot:form>
      <v-layout column>
        <v-text-field readonly hide-details class="mb-3" :label="$te('shifts.fields.opened_at') ? $t('shifts.fields.opened_at') : $t('shifts.fields.opened')" :value="openedAt" />
        <v-text-field readonly hide-details class="mb-3" :label="$te('shifts.fields.closed_at') ? $t('shifts.fields.closed_at') : $t('shifts.fields.closed')" :value="closedAt" />
        <v-text-field readonly hide-details class="mb-3" :label="$t('fields.operator')" :value="operator" />
        <v-text-field readonly hide-details class="mb-3" :label="$t('fields.station')" :value="station" />
        <v-text-field readonly hide-details class="mb-3" :label="$t('fields.device')" :value="device" />
        <v-text-field readonly hide-details class="mb-3" :label="$t('shifts.fields.opened_by')" :value="openedBy" />
        <v-text-field readonly hide-details class="mb-5" :label="$t('shifts.fields.closed_by')" :value="closedBy" />
        <div v-for="(paymentMethod, i) in paymentMethods" :key="i" :set="accountingState = getAccountingState(paymentMethod)">
          <v-layout column v-if="Object.keys(accountingState).length">
            <v-label>{{ paymentMethod.name }}</v-label>
            <v-layout v-for="(entry, j) in Object.entries(accountingState)" :key="j"
              :set1="currencyName = entry[0]"
              :set2="initialAmount = entry[1].initialAmount"
              :set3="finalAmount = entry[1].finalAmount"
              :set4="amountDiff = entry[1].amountDiff"
              align-center
              justify-end
              class="grey--text text--darken-2"
            >
              <span>{{initialAmount}} {{currencyName}}</span>
              <v-icon>mdi-menu-right</v-icon>
              <span>{{finalAmount}} {{currencyName}}</span>
            </v-layout>
            <span v-if="typeof amountDiff === 'number'" :class="{
              'success--text': amountDiff > 0,
              'grey--text': amountDiff === 0,
              'error--text': amountDiff < 0 }"
              class="text-right">
              ({{ amountDiff >= 0 ? '+' : '' }}{{formatAmount(amountDiff)}} {{currencyName}})
            </span>
          </v-layout>
        </div>
      </v-layout>
    </template>
  </side-form>
</template>

<script>
import dayjs from 'dayjs'
import moneyFormatMixins from '@/mixins/MoneyFormat'
import { mapState, mapGetters } from 'vuex'
export default {
  props: {
    triggerOpen: Date
  },
  computed: {
    ...mapState(['shifts']),
    ...mapGetters({
      currencies: 'configuration/currencies',
      paymentMethods: 'configuration/paymentMethods'
    }),
    openedAt () {
      return this.shifts.read ? dayjs(this.shifts.read.openedAt).format('DD/MM/YYYY HH:mm') : null
    },
    closedAt () {
      return this.shifts.read && this.shifts.read.closedAt ? dayjs(this.shifts.read.closedAt).format('DD/MM/YYYY HH:mm') : null
    },
    operator () {
      try { return this.shifts.read.operator.name } catch { return null }
    },
    station () {
      try { return this.shifts.read.station.name } catch { return null }
    },
    operator () {
      try { return this.shifts.read.operator.name } catch { return null }
    },
    device () {
      try {
        const device = this.shifts.read.device
        return device.label || device.model || device.id
      } catch { return null }
    },
    openedBy () {
      try { return this.shifts.read.openedByOperator.name } catch { return null }
    },
    closedBy () {
      try { return this.shifts.read.closedByOperator.name } catch { return null }
    },
    initialAccountingState () {
      return this.shifts.read ? this.shifts.read.initialAccountingState || [] : []
    },
    finalAccountingState () {
      return this.shifts.read ? this.shifts.read.finalAccountingState || [] : []
    }
  },
  methods: {
    getAccountingState (paymentMethod) {
      const accountingStateByCurrency = {}
      const initialAccountingStates = this.initialAccountingState.filter((accountingItem) => {
        return accountingItem.paymentMethodId === paymentMethod.id
      })
      const finalAccountingStates = this.finalAccountingState.filter((accountingItem) => {
        return accountingItem.paymentMethodId === paymentMethod.id
      })
      // Get all currencies for payment method
      const displayedCurrencies = [...initialAccountingStates, ...finalAccountingStates]
        .map(accountingState => {
          return accountingState.currencyId
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index && this.currencies.find(({ id }) => id === value)
        })
        .map(currencyId => {
          return this.currencies.find(({ id }) => id === currencyId)
        })
      displayedCurrencies.forEach(currency => {
        const initialAccountingState = initialAccountingStates.find((accountingItem) => {
          return accountingItem.currencyId === currency.id
        })
        const finalAccountingState = finalAccountingStates.find((accountingItem) => {
          return accountingItem.currencyId === currency.id
        })
        const amountDiff = finalAccountingState && initialAccountingState
          ? finalAccountingState.amount - initialAccountingState.amount
          : null
        accountingStateByCurrency[currency.name] = {
          initialAmount: initialAccountingState ? this.formatAmount(initialAccountingState.amount) : '--',
          finalAmount: finalAccountingState ? this.formatAmount(finalAccountingState.amount) : '--',
          amountDiff
        }
      })
      return accountingStateByCurrency
    }
  },
  components: {
    SideForm: () => import('@/components/app-components/SideForm.vue')
  },
  mixins: [
    moneyFormatMixins
  ]
}
</script>

<style lang='scss' scoped>
.v-label {
  margin: 12px 0;
}
</style>