export default {
  data () {
    return {
      maxDecimals: 2
    }
  },
  methods: {
    formatAmount (amount) {
      return Number(Math.round(parseFloat(amount + 'e' + this.maxDecimals)) + 'e-' + this.maxDecimals).toFixed(this.maxDecimals)
    },
    parsePrice (price) {
      var parsedPrice = 0
      price = price.replace(',', '.').replace(/[^\d.]/g, '') // price as   "4.1234" / ".25" / "1."
      const priceParts = price.split('.')
      if (priceParts.length > 1) {
        price = priceParts[0] + '.' + priceParts.slice(1).join('')
      }
      const priceFloat = isNaN(parseFloat(price)) ? 0 : parseFloat(price) // 4.1234 /  0.25 /   1
      const priceString = this.getFlooredFixed(priceFloat) // eg:                "4.12" ,  "0.25",  "1.00"
      var [euro, cents] = priceString.split('.') // eg:              ["4", "12"],["0","25"],["1", "00"]
      if (typeof cents === 'undefined') {
        cents = new Array(this.maxDecimals + 1).join('0')
      }
      cents = cents.substring(0, this.maxDecimals)
      while (cents.length < this.maxDecimals) {
        cents += '0'
      }
      parsedPrice = parseInt(euro, 10)
      parsedPrice += parseInt(cents, 10) / Math.pow(10, this.maxDecimals)
      return parsedPrice
    },
    sanitizePrice: (price, context) => {
      var sanitizedPrice = price.replace(',', '.').replace(/[^\d.]/g, '')
      const priceParts = sanitizedPrice.split('.')
      if (priceParts.length > 1) {
        sanitizedPrice = priceParts[0] + '.' + priceParts.slice(1).join('').substring(0, context.maxDecimals)
      }
      sanitizedPrice = sanitizedPrice.replace(/^0+(\d)/, '$1')
      return sanitizedPrice
    },
    getFlooredFixed (v) {
      var re = new RegExp('^-?\\d+(?:\\.\\d{0,' + this.maxDecimals + '})?')
      return v.toString().match(re)[0]
    },
    onlyInt (e) {
      if (!e.key.replace(/\D/g,'') || !e) e.preventDefault()
    }
  }
}
